
import { Controller } from "stimulus";
import SignaturePad from 'signature_pad';

export default class extends Controller {

  static targets = ['clientInput', 'clientCanvas', 'clientNotice', 'supplierInput', 'supplierCanvas', 'supplierNotice', 'form']

  connect() {

    if (this.hasClientCanvasTarget) {
        this.clientPadInit()
        this.clientNoticeTarget.classList.add('hidden')
    }

    if (this.hasSupplierCanvasTarget) {
        this.supplierPadInit()
        this.supplierNoticeTarget.classList.add('hidden')
    }
  }

  submitForm(event) {
    event.preventDefault();
    const name = event.currentTarget.getAttribute('name');

    this.saveSupplierPad();
    this.saveClientPad();

    const formAction = this.formTarget.action;
    // Append the parameter to the form action
    const url = new URL(formAction);
    url.searchParams.set('name', name);
    // Set the modified URL back to the form action
    this.formTarget.action = url.toString();

    this.formTarget.submit();
  }

  clientPadInit() {
    this.clientCanvasTarget.height = this.clientCanvasTarget.offsetHeight
    this.clientCanvasTarget.width = this.clientCanvasTarget.offsetWidth

    window.onresize = this.resizeCanvas(this.clientCanvasTarget)
    this.resizeCanvas(this.clientCanvasTarget)
    this.clientPad = new SignaturePad(this.clientCanvasTarget)
    this.clientPad.penColor = "rgb(0, 96, 177)"
  }

  supplierPadInit() {
    this.supplierCanvasTarget.height = this.supplierCanvasTarget.offsetHeight
    this.supplierCanvasTarget.width = this.supplierCanvasTarget.offsetWidth

    window.onresize = this.resizeCanvas(this.supplierCanvasTarget)
    this.resizeCanvas(this.supplierCanvasTarget)
    this.supplierPad = new SignaturePad(this.supplierCanvasTarget)
    this.supplierPad.penColor = "rgb(0, 96, 177)"
  }

   clearClientPad() {
      this.clientPad.clear()
      this.clientInputTarget.value = ''
      this.clientNoticeTarget.classList.add('hidden')
   }


   clearSupplierPad() {
      this.supplierPad.clear()
      this.supplierInputTarget.value = ''
      this.supplierNoticeTarget.classList.add('hidden')
   }

   saveClientPad() {
     if (this.hasClientCanvasTarget && !this.clientPad.isEmpty()) {
       this.clientInputTarget.value = this.clientPad.toDataURL('image/png')
     }
   }

   saveSupplierPad() {
     if (this.hasSupplierCanvasTarget && !this.supplierPad.isEmpty()) {
       this.supplierInputTarget.value = this.supplierPad.toDataURL('image/png')
     }
   }

  resizeCanvas(canvas) {
    const ratio = Math.max(window.devicePixelRatio || 1, 1)
    canvas.width = canvas.offsetWidth * ratio
    canvas.height = canvas.offsetHeight * ratio
    canvas.getContext('2d').scale(ratio, ratio)
    return
  }

}
